<template>
  <div v-for="section in expositionEntries" :key="section.name" class="mb-16">
    <h2 class="mb-4">{{ section.section }}</h2>
    <div v-for="entry in section.entries" :key="entry.year" class="mt-2">
      <ExpositionEntry :year="entry.year" :text="entry.text"> </ExpositionEntry>
      <hr class="mt-2" />
    </div>
  </div>
</template>

<script>
import ExpositionEntry from "../components/Expositions/ExpositionEntry.vue";

export default {
  name: "Vita",
  components: {
    ExpositionEntry,
  },
  data: function () {
    return {
      expositionEntries: this.$store.getters.getExpositionEntries,
    };
  },
};
</script>

<style>
p {
  font-family: "Open Sans", sans-serif;
}

h2 {
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  font-size: 16px;
}
</style>
